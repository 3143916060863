import React, { useEffect } from 'react';

export default () => {
  const fields = [
    {
      type: 'text',
      name: 'name',
      placeholder: 'Name',
      width: 'is-half',
      rules: 'required',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'Email',
      width: 'is-half',
      rules: 'email|required',
    },
    {
      type: 'text',
      name: 'phone',
      placeholder: 'Phone',
      width: 'is-half',
      rules: 'required',
    },
    {
      type: 'text',
      name: 'subject',
      placeholder: 'Subject / Property',
      width: 'is-half',
      rules: 'required',
    },
  ];

  // This needs tidied to prevent the script getting loaded in more than once
  useEffect(() => {
    let kwesScript = document.createElement('script');
    kwesScript.setAttribute('src', 'https://kwesforms.com/js/kwes.js');
    document.head.appendChild(kwesScript);
  }, []);

  return (
    <div className="our-form-wrapper">
      <div
        className="kwes-form"
        style={{ paddingTop: '20px', paddingBottom: '20px' }}
      >
        <form
          className="form"
          method="POST"
          action="https://kwesforms.com/api/foreign/forms/gbD46NOa1kS6pPStPN3n"
          no-reload
          success-message="Thanks for getting in contact, we will get back to you as soon as possible."
        >
          <div className="columns is-multiline">
            {fields.map(f => (
              <div className={`column ${f.width} field`} key={f.name}>
                <div className="control">
                  <input
                    type={f.type}
                    name={f.name}
                    placeholder={f.placeholder}
                    className="input"
                    rules={f.rules}
                  />
                </div>
              </div>
            ))}
            <div className="field column is-full">
              <div className="control">
                <textarea
                  name="message"
                  className="textarea"
                  placeholder="Message"
                />
              </div>
            </div>
            <div className="field column is-full">
              <div className="control">
                <button className="button" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
